














































































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    status: {
      type: String,
      required: false,
      default: "",
    },
  },

  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
    MTestEmail: () => import("@/components/molecules/m-test-email.vue"),
  },

  setup(_, { root }) {
    const { shortLanguageItems } = useSelectItems({ root });

    const model = reactive<{
      emailEditor: any;
      title: string;
      englishEditor: any;
      englishTitle: string;
    }>({
      emailEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      title: "",
      englishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      englishTitle: "",
    });

    const state = reactive({
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      editorLoaded: false,
      englishEditorLoaded: false,
      templateAdded: {} as any,
      englishTemplateAdded: {} as any,
      dialog: false,
      event: {},
      languageSwitch: "pl",
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      if (state.languageSwitch === "pl") {
        axiosInstance
          .get(`event/${root.$route.params.id}`)
          .then(({ data: { event } }) => {
            state.event = event;
            model.emailEditor.design =
              event.companyRegistrationMail?.design || undefined;
            model.title = event.companyRegistrationMail?.title || undefined;
            state.templateAdded = event.companyRegistrationMail?.design
              ? true
              : false;
            state.editorLoaded = true;
            state.templateAdded = event.companyRegistrationMail;
            state.englishEditorLoaded = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            state.loaded = true;
            state.loading = false;
          });
      } else if (state.languageSwitch === "en") {
        axiosInstance
          .get(`event/${root.$route.params.id}`)
          .then(({ data: { event } }) => {
            state.event = event;
            model.englishEditor.design =
              event.englishCompanyRegistrationMail?.design || undefined;
            model.englishTitle =
              event.englishCompanyRegistrationMail?.title || undefined;
            state.englishEditorLoaded = event.englishCompanyRegistrationMail
              ?.design
              ? true
              : false;
            state.englishTemplateAdded = event.englishCompanyRegistrationMail;
            state.englishEditorLoaded = true;
            state.editorLoaded = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            state.loaded = true;
            state.loading = false;
          });
      }
    };

    onMounted(fetchEvent);
    watch(() => state.languageSwitch, fetchEvent);

    const onSubmit = async (relation?: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (
        (state.languageSwitch === "pl" && state.templateAdded) ||
        (state.languageSwitch === "en" && state.englishTemplateAdded)
      ) {
        const data = {
          template:
            state.languageSwitch === "pl"
              ? model.emailEditor.html || undefined
              : model.englishEditor.html || undefined,
          design:
            state.languageSwitch === "pl"
              ? model.emailEditor?.design || undefined
              : model.englishEditor?.design || undefined,
          title:
            state.languageSwitch === "pl"
              ? model.title || undefined
              : model.englishTitle || undefined,
        };

        const id =
          state.languageSwitch === "pl"
            ? state.templateAdded?.id
            : state.englishTemplateAdded?.id;
        axiosInstance
          .put(`message/${id}`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("layout.misc.templateEdited"),
            });
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        const data = {
          template:
            state.languageSwitch === "pl"
              ? model.emailEditor.html || undefined
              : model.englishEditor.html || undefined,
          design:
            state.languageSwitch === "pl"
              ? model.emailEditor?.design || undefined
              : model.englishEditor?.design || undefined,
          title:
            state.languageSwitch === "pl"
              ? model.title || undefined
              : model.englishTitle || undefined,
          relation: relation,
          eventId: root.$route.params.id,
        };
        axiosInstance
          .post("message", data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("layout.misc.templateSaved"),
            });
            fetchEvent();
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    return { model, state, onSubmit, getErrorMessage, shortLanguageItems };
  },
});
